'use client';

import { FC, PropsWithChildren } from 'react';

import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { ThemeProvider } from '@common/atoms/ThemeProvider';
import { ResolvedContextData } from '@common/defaults';
import { ContextDataProvider } from '@common/useContextData';

interface Props extends PropsWithChildren {
    contextData: ResolvedContextData;
    theme: string;
}

export const ContextProviders: FC<Props> = ({ children, contextData, theme }: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <SupertokensWrapper contextData={contextData}>
                <ContextDataProvider contextData={contextData}>{children}</ContextDataProvider>
            </SupertokensWrapper>
        </ThemeProvider>
    );
};
