'use client';

import { FC, PropsWithChildren } from 'react';
import { ThemeProvider as Provider } from 'next-themes';

interface Props extends PropsWithChildren {
    theme: string;
}

export const ThemeProvider: FC<Props> = ({ children, theme }) => {
    return (
        <Provider
            attribute="class"
            enableColorScheme={false}
            value={{ light: `theme-${theme}-light`, dark: `theme-${theme}-dark` }}
        >
            {children}
        </Provider>
    );
};
