'use client';

import { FC, memo, useMemo } from 'react';

import { useTranslation } from '@cms/i18n';
import { Link } from '@common/atoms/Link';
import { PlatformID, Tag } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { checkIfModuleSectionEnabeled } from '@common/utils/betting/checkIfBettingEnabled';
import { PlatformLogo, PositionVariant } from '@web/atoms/PlatformLogo/PlatformLogo';
import { LinkList, LinkTarget } from '@web/molecules/LinkList/LinkList';
import { getSocialLinks, SocialLinkBlock } from '@web/molecules/SocialLinkBlock';
import { Route, RouteByTagType } from '@web/routing';

import { GridWrapper } from './atoms/GridWrapper';
import { AppUrls } from './molecules/AppUrls';
import { BottomBar } from './molecules/BottomBar';
import { GridItem, GridWrapperLayout } from './atoms';

import styles from './Footer.module.scss';

const PRIVACY_STATEMENT_URL = 'https://privacy.pxr.nl';

export const MAXIMUM_TRENDING_TAGS_TO_SHOW = 8;
export const MINIMUM_TRENDING_TAGS_TO_SHOW = 6;

const getTrendingLinks = (trendingTags: Tag[] = [], linkLimit: number) => {
    if (!trendingTags.length) {
        return [];
    }

    return trendingTags
        .filter((_, index) => index < linkLimit)
        .map((tag: Tag) => {
            const tagTypeRoute = RouteByTagType[tag.tagType];
            return {
                query: { tag: tag.tagSlug },
                route: tagTypeRoute,
                label: tag.title,
            };
        });
};

const Component: FC = () => {
    const { context, platform, trendingTags = [], modulesBySection, route } = useContextData();

    const __footer = useTranslation('footer').t;
    const __betting = useTranslation('betting').t;
    const __url = useTranslation('url').t;

    const allowBetting = checkIfModuleSectionEnabeled(modulesBySection);
    const socialLinks = useMemo(() => getSocialLinks(context), [context]);
    const tagCount: number = allowBetting ? MINIMUM_TRENDING_TAGS_TO_SHOW : MAXIMUM_TRENDING_TAGS_TO_SHOW;

    const trendingLinks = useMemo(() => getTrendingLinks(trendingTags, tagCount), [tagCount, trendingTags]);

    if (route === Route.Signin) {
        return null;
    }

    return (
        // FIXME: PB-5484 - Add newsletter sign up component above (or inside) footer
        <footer className={`${styles.Footer} _gp-redesign _including-children`}>
            <GridWrapper classes={'wrapper full-width'}>
                <GridItem gridArea={GridWrapperLayout.PLATFORM_BRAND}>
                    <Link href="/">
                        <PlatformLogo
                            classes={styles.spacer}
                            extraPadding
                            platformID={platform.id}
                            positionVariant={PositionVariant.FOOTER}
                        />
                    </Link>
                    {context.androidAppUrl || context.iosAppUrl ? (
                        <h3>{__footer('label.download-app')}</h3>
                    ) : null}
                    <AppUrls />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.ABOUT}>
                    <h3>{__footer('title.about-the-website')}</h3>
                    <LinkList
                        links={[
                            {
                                query: { slug: __url('colophon') },
                                label: __footer('label.colophon'),
                                route: Route.Page,
                            },
                            {
                                query: { slug: __url('vacancies') },
                                label: __footer('label.work-at'),
                                route: Route.Page,
                            },
                            {
                                query: { slug: __url('rss-feeds') },
                                label: __footer('label.rss-feeds'),
                                route: Route.Page,
                            },
                            {
                                query: { slug: __url('terms-of-service') },
                                label: __footer('label.terms-conditions'),
                                route: Route.Page,
                            },
                            {
                                url: PRIVACY_STATEMENT_URL,
                                target: LinkTarget.SELF,
                                label: __footer('label.privacy-statement'),
                            },
                        ]}
                    />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.TRENDING} title={__footer('title.trending')}>
                    {trendingLinks ? <LinkList links={trendingLinks} /> : null}
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.SOCIAL} title={__footer('title.follow-us')}>
                    <SocialLinkBlock socialLinks={socialLinks} platformID={platform.id} />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.CONTACT} title={__footer('title.contact')}>
                    <LinkList
                        links={[
                            {
                                query: { slug: 'contact' },
                                label: __footer('label.contact'),
                                route: Route.Page,
                            },
                            {
                                query: { slug: __url('tip-the-editors') },
                                label: __footer('label.tip-editors'),
                                route: Route.Page,
                            },
                        ]}
                    />
                    <h3>{__footer('title.commerce-and-partnerships')}</h3>
                    <LinkList
                        links={[
                            {
                                query: { slug: __url('advertise') },
                                label: __footer('label.advertise'),
                                route: Route.Page,
                            },
                        ]}
                    />
                    {allowBetting ? (
                        <LinkList
                            links={[
                                {
                                    query: { slug: __url('play-responsibly') },
                                    label: __betting('label.play-responsible'),
                                    route: Route.Page,
                                },
                                {
                                    query: { slug: __url('play-responsibly') },
                                    label: __betting('label.betting-settings'),
                                    route: Route.Page,
                                },
                            ]}
                        />
                    ) : null}
                </GridItem>
            </GridWrapper>
            <BottomBar platformID={platform.id} platformName={platform.name || PlatformID.VP} />
        </footer>
    );
};

Component.displayName = 'Footer';
export const Footer = memo(Component);
