import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { range } from 'lodash';

import { FeaturedItem } from '@common/clients/api';
import { DefaultDynamicSizes } from '@web/atoms/WebpImage';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';

import { FeaturedCarrouselItem, ItemLayout } from './FeaturedCarrouselItem';

import styles from './FeaturedCarrousel.module.scss';

export enum CarouselLayout {
    MINIFIED_SIDE = 'Minified_Side',
    EXPANDED_SIDE = 'Expanded_Side',
    SINGLE_MAIN = 'Single_Main',
    TWIN_MAINS = 'Twin_Mains',
    EXPANDED_FOOTER = 'Expanded_Footer',
}

export interface Props {
    items: FeaturedItem[];
    isEditable?: boolean;
    desktopLayout?: CarouselLayout;
    responsiveLayout?: CarouselLayout;
}

const scrollToItem = (index: number, carrouselRef: RefObject<HTMLUListElement>) => {
    if (!carrouselRef.current) return;
    const rect = carrouselRef.current.getBoundingClientRect();
    const width = rect.right - rect.left;
    carrouselRef.current.scrollTo(index * width, 0);
};

const getItemsPosition = (firstFeaturedElementRef?: RefObject<HTMLLIElement>) => {
    if (!firstFeaturedElementRef?.current) return 0;
    return firstFeaturedElementRef.current.getBoundingClientRect().left || 0;
};

const getImageSizes = (layout: CarouselLayout, index: number): string => {
    switch (layout) {
        case CarouselLayout.EXPANDED_SIDE:
            switch (index) {
                case 0:
                    return DefaultDynamicSizes.TwoThird;
                default:
                    return DefaultDynamicSizes.OneThird;
            }
        default:
            return '100vw';
    }
};

const getItemClassName = (layout: CarouselLayout, index: number): string => {
    const classNames: string[] = [];

    switch (layout) {
        case CarouselLayout.EXPANDED_SIDE:
            classNames[0] = styles.xxlarge;
            classNames[1] = `${styles.medium} ${styles['upper-item']}`;
            classNames[2] = styles.medium;
            break;

        case CarouselLayout.MINIFIED_SIDE:
            classNames[0] = styles.full;
            classNames[1] = styles.xsmall;
            classNames[2] = styles.xsmall;
            classNames[3] = styles.xsmall;
            classNames[4] = styles.xsmall;
            break;

        case CarouselLayout.SINGLE_MAIN:
            classNames[0] = styles.full;
            break;

        case CarouselLayout.TWIN_MAINS:
            classNames[0] = styles.large;
            classNames[1] = styles.large;
            break;

        case CarouselLayout.EXPANDED_FOOTER:
            classNames[0] = styles.xlarge;
            classNames[1] = styles.small;
            classNames[2] = styles.small;
            classNames[3] = styles.small;
            break;
    }

    return `${styles['responsive-article']} ${classNames[index] || ''}`;
};

export const FeaturedCarrousel: FC<Props> = ({
    items,
    isEditable,
    desktopLayout = CarouselLayout.EXPANDED_SIDE,
    responsiveLayout = CarouselLayout.EXPANDED_SIDE,
}: Props): JSX.Element => {
    const isResponsiveMode = useResponsive({ direction: MediaQueryDirection.below, size: MediaQuery.m });

    const layout = useMemo(
        () => (isResponsiveMode ? responsiveLayout : desktopLayout),
        [desktopLayout, isResponsiveMode, responsiveLayout],
    );

    const [activeElement, setActiveElement] = useState<number>(0);

    const carrouselRef = useRef<HTMLUListElement>(null);
    const firstFeaturedElementRef = useRef<HTMLLIElement>(null);

    const itemsToDisplay = useMemo(() => {
        const totalNumber: Record<CarouselLayout, number> = {
            [CarouselLayout.EXPANDED_SIDE]: 3,
            [CarouselLayout.MINIFIED_SIDE]: 5,
            [CarouselLayout.SINGLE_MAIN]: 1,
            [CarouselLayout.TWIN_MAINS]: 2,
            [CarouselLayout.EXPANDED_FOOTER]: 4,
        };

        return items?.slice(0, totalNumber[layout]);
    }, [items, layout]);

    useEffect(() => {
        let swipStartPosition = getItemsPosition(firstFeaturedElementRef);
        const diffMargin = 20;

        carrouselRef.current?.addEventListener('scroll', () => {
            // Don't add event listner on big screens.
            const isMiniView =
                carrouselRef.current &&
                firstFeaturedElementRef?.current &&
                carrouselRef.current.offsetWidth < carrouselRef.current.scrollWidth;

            if (isMiniView) {
                const swipeCurrentPosition = getItemsPosition(firstFeaturedElementRef);
                const swipingDiff: number = swipStartPosition - swipeCurrentPosition;

                // In order to improve the performance, evaluate the active featured-item every 20px of swiping
                if (Math.abs(swipingDiff) > diffMargin) {
                    const firstRec = firstFeaturedElementRef?.current?.getBoundingClientRect();
                    if (firstRec) {
                        const width = firstRec.right - firstRec.left;
                        const leftPosition = firstRec?.left;
                        const currentIndex = Math.round(leftPosition / -width);
                        swipStartPosition = getItemsPosition(firstFeaturedElementRef);
                        setActiveElement(currentIndex);
                    }
                }
            }
        });
    }, []);

    const hasScrollingIndicator = useMemo(
        () => [CarouselLayout.EXPANDED_SIDE, CarouselLayout.MINIFIED_SIDE].includes(layout),
        [layout],
    );

    const containerClassNames = useMemo(
        () => ({
            [CarouselLayout.EXPANDED_FOOTER]: styles['ef-container'],
            [CarouselLayout.EXPANDED_SIDE]: styles['es-container'],
            [CarouselLayout.MINIFIED_SIDE]: styles['ms-container'],
            [CarouselLayout.SINGLE_MAIN]: styles['es-container'],
            [CarouselLayout.TWIN_MAINS]: styles['tm-container'],
        }),
        [],
    );

    const rootStyle = layout === CarouselLayout.EXPANDED_FOOTER ? { height: '60vw' } : undefined;

    return (
        <aside className={styles.FeaturedCarrousel} style={rootStyle}>
            <ul ref={carrouselRef} className={containerClassNames[layout]}>
                {layout !== CarouselLayout.MINIFIED_SIDE ? (
                    <>
                        {itemsToDisplay?.map((item, index) => {
                            const itemLayout =
                                index !== 0 && layout === CarouselLayout.EXPANDED_FOOTER
                                    ? ItemLayout.FOOTER
                                    : ItemLayout.EXPANDED;

                            return (
                                <FeaturedCarrouselItem
                                    key={`featuredItem${index}`}
                                    index={index}
                                    item={item}
                                    className={getItemClassName(layout, index)}
                                    itemRef={index === 0 ? firstFeaturedElementRef : undefined}
                                    layout={itemLayout}
                                    isEditable={isEditable}
                                    imageSizes={getImageSizes(layout, index)}
                                />
                            );
                        })}
                    </>
                ) : null}

                {layout === CarouselLayout.MINIFIED_SIDE ? (
                    <>
                        <FeaturedCarrouselItem
                            key={'featuredItem0'}
                            index={0}
                            item={itemsToDisplay[0]}
                            className={getItemClassName(layout, 0)}
                            itemRef={firstFeaturedElementRef}
                            isEditable={isEditable}
                            layout={ItemLayout.EXPANDED_WITH_SIDE}
                            imageSizes={getImageSizes(layout, 0)}
                        />

                        <hr className="vertical"></hr>

                        <div className={styles['minified-side-container']}>
                            {itemsToDisplay?.map((item, index) => {
                                if (index !== 0) {
                                    return (
                                        <FeaturedCarrouselItem
                                            key={`featuredItem${index}`}
                                            index={index}
                                            item={item}
                                            isEditable={isEditable}
                                            className={getItemClassName(layout, index)}
                                            layout={ItemLayout.MINIFIED}
                                            imageSizes={getImageSizes(layout, index)}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>

                        {itemsToDisplay?.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <FeaturedCarrouselItem
                                        key={`featuredItem${index}`}
                                        index={index}
                                        item={item}
                                        className={`${getItemClassName(layout, index)} ${
                                            styles['responsive-only']
                                        }`}
                                        layout={ItemLayout.EXPANDED_WITH_SIDE}
                                        isEditable={isEditable}
                                        imageSizes={getImageSizes(layout, index)}
                                    />
                                );
                            }
                            return null;
                        })}
                    </>
                ) : null}
            </ul>

            {hasScrollingIndicator ? (
                <ul className={styles['slider-bar']}>
                    {range(0, itemsToDisplay.length).map((index) => {
                        const indicatorClassName =
                            index === activeElement
                                ? `${styles.indicator} ${styles.active}`
                                : styles.indicator;

                        return (
                            <li
                                id={`indicator${index}`}
                                key={`indicator${index}`}
                                onClick={() => {
                                    scrollToItem(index, carrouselRef);
                                }}
                            >
                                <span className={indicatorClassName} />
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </aside>
    );
};
